import Vue from "vue";
import Router from "vue-router";
import Main from "./components/Main.vue";
import About from "./components/About.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { title: "About Me" },
  },
  { path: "*", redirect: "/" },
];

const router = new Router({
  mode: 'history',
  routes,
});

const aboutRoute = {
  path: "/about#contact",
  redirect: "/about",
};

router.addRoute(aboutRoute);

export default router;
