<template>
  <div>
    <b-icon
      @click="scrollToComponent('up')"
      :class="{ 'arrow d-none': true, 'd-block': scrolled }"
      icon="b-icon-arrow-up"
    />
    <b-icon
      v-if="this.isMobile"
      @click="setNavbarMobile()"
      class="kebabmenu"
      icon="b-icon-list"
    />
    <div id="header" class="pad-lefri">
      <div class="left">
        <p class="title fh">It's Garda.</p>
        <p class="description fm">
          I will proudly say that Im your go-to
          <b>Creative Digital Marketer</b>
          who loves storytelling within the digital sphere. Been in the
          marketing game for almost a good solid 3 to 4 years, juggling
          everything from crafting content to diving deep into analytics. I
          started as a Graphic Designer, Visual Communications focusing on clean
          Swiss designs and Content Writing / Copywriting. I’m a Computer
          Science graduate and decided to continue my passion in Arts through
          the marketing lens.
        </p>
        <a href="/about" class="more fm">
          know more.
        </a>
      </div>
      <div :class="{ right: true, 'd-none': this.isMobile && !isNavbarOn }">
        <ul>
          <li
            v-for="(item, index) in navbarItems"
            :key="index"
            @click="scrollToComponent(item.target)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>

    <div id="works" class="pad-lefri">
      <p class="title">works.</p>
      <div class="image" style="margin-bottom: 0.94vw;">
        <div v-for="(item, index) in behanceData.slice(0, 6)" :key="index">
          <a :href="item.url" target="_blank">
            <img :src="item.imageUrl" :alt="item.title" target="_blank" />
          </a>
        </div>
      </div>
      <div class="more">
        <a
          href="https://www.behance.net/rizkyamien/projects"
          class="fm"
          target="_blank"
        >
          see more.
        </a>
      </div>
    </div>

    <div id="blog" class="pad-lefri">
      <p class="title">blogs.</p>
      <div
        class="post"
        v-for="(item, index) in (isMobile ? 6 : 2)"
        :key="index"
      >
        <div class="row">
          <div
            v-for="(innerItem, innerIndex) in (isMobile ? 1 : 3)"
            :key="innerIndex"
            class="col-4"
          >
            <div
              v-if="index * (isMobile ? 1 : 3) + innerIndex < mediumData.length"
            >
              <p class="date">
                {{ mediumData[index * (isMobile ? 1 : 3) + innerIndex].date }}
              </p>
              <a
                :href="mediumData[index * (isMobile ? 1 : 3) + innerIndex].url"
                class="title"
                target="_blank"
              >
                {{ mediumData[index * (isMobile ? 1 : 3) + innerIndex].title }}
              </a>
              <p class="description fm">
                {{
                  mediumData[
                    index * (isMobile ? 1 : 3) + innerIndex
                  ].description
                    .split(' ')
                    .slice(0, 25)
                    .join(' ') + '...'
                }}
              </p>
              <a
                :href="mediumData[index * (isMobile ? 1 : 3) + innerIndex].url"
                target="_blank"
                class="readmore fm"
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="more">
        <a href="https://rizkyamien.medium.com/" class="fm" target="_blank">
          see more.
        </a>
      </div>
    </div>

    <div id="podcast" class="pad-lefri">
      <div class="left">
        <p class="title mb-0">talkingtale</p>
        <p class="description fm">
          Born in 2018, on my bike while cruising around town, having a good
          talk with myself. Suddenly, the idea of Talkingtale hits me. So, I
          started recording solo conversations and continue bringing up
          fascinating folks to talk to. We dish about how to get things done,
          staying happy, and living life. Now, we’ve got a total of 4000+
          Listeners. So, It’s not just about dropping knowledge bombs and being
          deep but also entertaining in a genuine way.
        </p>

        <div class="more">
          <a
            href="https://podcasters.spotify.com/pod/show/talkingtale"
            class="fm"
            target="_blank"
          >
            listen more.
          </a>
        </div>
      </div>
      <div class="right">
        <a
          href="https://podcasters.spotify.com/pod/show/talkingtale"
          target="_blank"
        >
          <img
            src="https://s3-us-west-2.amazonaws.com/anchor-generated-image-bank/production/podcast_uploaded400/818659/818659-1551371307468-dc4fa0c278686.jpg"
            alt="talkingtale"
            target="_blank"
          />
        </a>
      </div>
    </div>

    <div id="instagram" class="pad-lefri">
      <a
        v-for="(item, index) in instagram"
        :key="index"
        :href="item.url"
        target="_blank"
      >
        <img :src="item.img" />
      </a>
      <div class="row">
        <div class="col-6 icon">
          <b-icon class="bi" icon="b-icon-instagram" />
        </div>
        <div class="col-6 username">
          <a
            href="https://www.instagram.com/rizkyamienn/"
            class="fm"
            target="_blank"
          >
            @rizkyamien
          </a>
        </div>
      </div>
    </div>

    <div
      id="cv"
      :style="{
        backgroundImage: 'url(' + require('@/assets/Footer.jpeg') + ')',
      }"
    >
      <div class="content">
        <a
          href="https://drive.google.com/file/d/1pTRE0QowLkI6WupLwJdjfteUgBKy3AjL/view?usp=sharing"
          target="_blank"
        >
          download cv.
        </a>
        <a
          href="https://drive.google.com/file/d/15fdWI9ZLNN1MAW2h7frPSUce_YDGRO6g/view?usp=sharing"
          target="_blank"
        >
          download portofolio.
        </a>
      </div>
    </div>

    <div class="footer pad-lefri">
      <div class="col-6">
        <a href="/about#social">
          contact.
        </a>
      </div>
      <div class="col-6 text-right">
        <p>Rizkyamien @2024 All Rights Reserved //</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import instagram1 from '@/assets/Instagram/1.jpg'
import instagram2 from '@/assets/Instagram/2.jpg'
import instagram3 from '@/assets/Instagram/3.jpg'
import instagram4 from '@/assets/Instagram/4.jpg'
import instagram5 from '@/assets/Instagram/5.jpg'
import instagram6 from '@/assets/Instagram/6.jpg'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Main',
  data() {
    return {
      isMobile: null,
      navbarItems: [
        { label: 'about.', target: 'header' },
        { label: 'works.', target: 'works' },
        { label: 'blog.', target: 'blog' },
        { label: 'podcast.', target: 'podcast' },
        { label: 'instagram.', target: 'instagram' },
        { label: 'cv & portofolio.', target: 'cv' },
      ],
      behanceData: [
        {
          title: 'Fast & Move - Coffee Cup Design',
          url:
            'https://www.behance.net/gallery/193290065/Fast-Move-Coffee-Cup-Design',
          imageUrl:
            'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/100c0d193290065.65e93c5846d50.png',
        },
        {
          title: 'Streetspeaks.com - Branding Design',
          url:
            'https://www.behance.net/gallery/129520637/Streetspeakscom-Branding-Design',
          imageUrl:
            'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/51342c129520637.616d39527fb80.png',
        },
        {
          title: 'Redesigning a Mobile Banking App',
          url:
            'https://www.behance.net/gallery/65682045/Redesigning-a-Mobile-Banking-App',
          imageUrl:
            'https://mir-s3-cdn-cf.behance.net/project_modules/fs/b04ad265682045.5afc8359a9b55.png',
        },
        {
          title: 'Kik Messenger Re-Design for PC',
          url:
            'https://www.behance.net/gallery/64638687/Kik-Messenger-Re-Design-for-PC',
          imageUrl:
            'https://mir-s3-cdn-cf.behance.net/project_modules/fs/91f8a064638687.5ad8aad1a3972.png',
        },
        {
          title: 'Music Player UI',
          url: 'https://www.behance.net/gallery/64150109/Music-Player-UI',
          imageUrl:
            'https://mir-s3-cdn-cf.behance.net/project_modules/fs/0610ad64150109.5ac89f46810bd.png',
        },
        {
          title: '404 Page',
          url: 'https://www.behance.net/gallery/64132413/404-Page',
          imageUrl:
            'https://mir-s3-cdn-cf.behance.net/project_modules/fs/c67ae464132413.5ac7c527855dc.png',
        },
      ],
      mediumData: [],
      scrolled: false,
      instagram: [
        {
          img: instagram1,
          url: 'https://www.instagram.com/p/CF3ZXodltnN/',
        },
        {
          img: instagram2,
          url: 'https://www.instagram.com/p/CWH0ysHPKGn/',
        },
        {
          img: instagram3,
          url: 'https://www.instagram.com/p/CU7Vo2Ol7eu/',
        },
        {
          img: instagram4,
          url: 'https://www.instagram.com/p/CwDWCkOMG8y/',
        },
        {
          img: instagram5,
          url: 'https://www.instagram.com/p/C1gSZi2s_ID/',
        },
        {
          img: instagram6,
          url: 'https://www.instagram.com/p/Cx_dVY5MqQF/',
        },
      ],
      isNavbarOn: false,
    }
  },
  created() {
    this.fetchMedium()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.checkIfMobile()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth < 500
    },
    fetchMedium() {
      const url =
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@rizkyamien'
      axios
        .get(url)
        .then((response) => {
          this.mediumData = response.data.items.map((item) => {
            const raw = item.description
            const paragraphMatches = [...raw.matchAll(/<p>(.*?)<\/p>/g)]
            const paragraphs = paragraphMatches
              .slice(0, 3)
              .map((match) => match[1])
            const description = paragraphs.join('\n')

            const imageRegex = /<img.*?src="(.*?)".*?>/
            const matchRegex = raw.match(imageRegex)
            const imageUrl = matchRegex ? matchRegex[1] : null

            return {
              title: item.title,
              description: description,
              url: item.guid,
              date: new Date(item.pubDate).toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              }),
              imageUrl: imageUrl,
            }
          })
        })
        .catch((error) => {
          console.error('Error fetching feed:', error)
        })
    },
    scrollToComponent(componentId) {
      if (componentId === 'up') {
        const element = document.getElementById('header')
        return element.scrollIntoView({ behavior: 'smooth' })
      }

      const element = document.getElementById(componentId)
      if (componentId === 'header') {
        return this.$router.push({ name: 'About' })
      }

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    handleScroll() {
      if (window.scrollY >= window.innerHeight) {
        if (!this.scrolled) {
          this.scrolled = true
        }
      } else {
        if (this.scrolled) {
          this.scrolled = false
        }
      }
    },
    setNavbarMobile() {
      this.isNavbarOn = !this.isNavbarOn
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 4.8vw;
  font-weight: 900;
}

#header {
  height: 100vh;
  padding-top: 8.89vh;
  display: flex;
}

#header .left {
  flex-grow: 0.9156;
  padding-top: 44.51vh;
}

#header .title {
  font-size: 4.28vw;
  font-weight: 900;
  margin-bottom: 1.3vw;
}

#header .description {
  font-size: 0.94vw;
  max-width: 40.63vw;
}

#header .description b {
  font-size: 1.25vw;
}

#header .more,
#works .more,
#blog .more,
#podcast .more {
  font-size: 1.25vw;
  font-weight: 300;
  cursor: pointer;
  width: max-content;
}

#header .more:hover,
#works .more:hover,
#blog .more:hover,
#blog .post .readmore:hover,
#podcast .more:hover {
  font-weight: 900;
}

#header .right {
  position: fixed;
  right: 5.73vw;
  z-index: 1;
}

#header ul {
  list-style: none;
}

#header li {
  list-style: none;
  font-size: 1.26vw;
  cursor: pointer;
  width: max-content;
  transition: all 0.5s ease;
  background: #fff;
}

#header li:hover {
  font-weight: 900;
  font-size: 1.56vw;
}

#works {
  height: 100vh;
  padding-top: 7.86vw;
}

#works .title {
  margin-bottom: 2.81vw;
}

#works .image {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 1rem;
  max-width: 100%;
}

#works img {
  width: 19.79vw;
  height: 13.75vw;
  object-fit: cover;
}

#works .more,
#blog .more {
  margin-top: 1.88vw;
  margin-left: auto;
}

#blog {
  height: 100vh;
  padding-top: 8.02vw;
}

#blog .title {
  margin-bottom: 3.59vw;
}

#blog .post {
  display: flex;
  justify-content: space-between;
}

#blog .col-4 {
  flex: 0 0 33.33%;
}

#blog .date {
  font-size: 0.78vw;
  font-style: italic;
  color: #7a7a7a;
  margin-bottom: 0.28vw;
}

#blog img {
  width: 100%;
  height: 10vw;
  margin-bottom: 0.28vw;
  object-fit: cover;
}

#blog .post {
  margin-bottom: 2.03vw;
}

#blog .post .title {
  font-size: 1.91vw;
  font-weight: 600;
  margin-bottom: 0.28vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#blog .description {
  font-size: 0.78vw;
  margin-bottom: 0.78vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#blog .readmore {
  font-size: 0.78vw;
  color: #212529;
}

#blog .more {
  margin-top: 4.53vw;
}

#podcast {
  height: 100vh;
  padding-top: 15.63vw;
  display: flex;
}

#podcast .left {
  flex: 0 0 36.41vw;
  padding-top: 1.67vw;
  flex-grow: 0;
}

#podcast .left .description {
  font-size: 0.94vw;
  max-width: 31.25vw;
  margin-bottom: 1.41vw;
}

#podcast .right {
  flex-grow: 1;
}

#podcast .right img {
  transform: scale(1, 1);
  width: 100%;
  object-fit: contain;
}

#instagram {
  height: 100vh;
  padding-top: 5.73vw;
  display: block;
}

#instagram img {
  width: 33.33%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

#instagram .icon {
  margin-top: 1.98vw;
  align-self: center;
}

#instagram .username {
  margin-top: 1.98vw;
  text-align: right;
}

#instagram .icon .bi,
#instagram .username a {
  font-size: 1.25vw;
}

#instagram .username a:hover {
  font-weight: 900;
}

#cv {
  height: 100vh;
  display: flex;
  background-size: cover;
}

#cv .content {
  width: 13vw;
  height: 5.73vw;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

#cv a {
  text-align: center;
  /* display: inline-block; */
  font-size: 1.26vw;
  display: flex;
  color: #fff !important;
}

#cv a:hover {
  font-weight: 900;
}

.footer {
  height: 10.78vw;
  padding-top: 4.9vw;
  display: flex;
}

.footer a,
.footer p {
  font-size: 1.25vw;
}

.footer a:hover {
  font-weight: 900;
}

@media screen and (max-width: 500px) {
  .kebabmenu {
    position: fixed;
    top: 5vw;
    right: 5vw;
    background: #fff;
    color: #000;
    font-size: 15vw;
    z-index: 1;
  }

  #header {
    padding-top: 2vh;
    display: flex;
  }

  #header .left {
    flex-grow: 0.9156;
    padding-top: 20vh;
  }

  .title {
    font-size: 18vw;
  }

  #header .title {
    font-size: 16vw;
    margin-bottom: 5vw;
  }

  #header .description {
    font-size: 4vw;
    max-width: unset;
    margin-bottom: 10vw;
  }

  #header .description b {
    font-size: 5vw;
  }

  #header .more,
  #works .more,
  #blog .more,
  #podcast .more {
    font-size: 6vw;
  }

  #header .right {
    position: fixed;
    top: 23vw;
    right: 5vw;
  }

  #header ul {
    background: #fff;
    font-size: 5vw;
    padding-inline-start: 3vw;
    width: 47vw;
  }

  #header li {
    font-size: 5vw;
  }

  #header li:hover {
    font-size: 6vw;
  }

  #works {
    padding-top: 30vw;
  }

  #works .title {
    font-size: 18vw;
    margin-bottom: 10vw;
  }

  #works .image {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }

  #works img {
    width: 45vw;
    height: 30vw;
  }

  #works .more,
  #blog .more {
    margin-top: 10vw;
  }

  #blog {
    padding-top: 6vw;
  }

  #blog .title {
    margin-bottom: 6vw;
  }

  #blog .post {
    display: flex;
    justify-content: space-between;
  }

  #blog .col-4 {
    flex: 0 0 100% !important;
    max-width: 100%;
    margin-bottom: 3vw;
    padding-left: 0;
  }

  #blog .date {
    font-size: 3vw;
    margin-bottom: 1vw;
  }

  #blog .post {
    margin-bottom: 2.03vw;
  }

  #blog .post .row {
    margin-left: unset;
    margin-right: unset;
  }

  #blog .post .title {
    font-size: 4vw;
    margin-bottom: 3vw;
    -webkit-line-clamp: 1;
  }

  #blog .description {
    display: none;
  }

  #blog .readmore {
    font-size: 3vw;
  }

  #blog .more {
    margin-top: 6vw;
  }

  #podcast {
    padding-top: 15.63vw;
    display: block;
  }

  #podcast .left {
    padding-top: 0vw;
  }

  #podcast .left .description {
    font-size: 4vw;
    max-width: 100%;
    margin-bottom: 5vw;
    margin-top: 5vw;
  }

  #podcast .right {
    width: 70%;
    margin-top: 10vw;
  }

  #instagram {
    padding-top: 25vw;
  }

  #instagram img {
    width: 50%;
  }

  #instagram .icon,
  #instagram .username {
    margin-top: 8vw;
  }

  #instagram .icon .bi,
  #instagram .username a {
    font-size: 4.5vw;
  }

  #cv .content {
    width: 50vw;
  }

  #cv a {
    font-size: 5vw;
  }

  .footer {
    height: 20vw;
    padding: 6vw;
  }

  .footer > .col-6 {
    padding: 0;
  }

  .footer .text-right {
    align-self: center;
  }

  .footer a,
  .footer p {
    font-size: 2vw;
    margin-bottom: 0;
  }
}
</style>
