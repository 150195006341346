<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: 'Helvetica', Merriweather, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  background-color: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
}

* {
  scrollbar-color: #000 #f1f1f1;
}

a {
  color: #212529 !important;
}

a:hover {
  text-decoration: none !important;
}

.pad-lefri {
  padding-left: 19.27vw;
  padding-right: 19.27vw;
}

.arrow {
  font-size: 3.07vw;
  position: fixed;
  left: 8.13vw;
  bottom: 5.1vw;
  z-index: 1;
}

.arrow:hover {
  cursor: pointer;
}

.fh {
  font-family: 'Helvetica', serif;
}

.fm {
  font-family: 'Merriweather', serif;
}

#carousel-2 .carousel-caption p {
  font-family: 'Merriweather', serif;
}

@media screen and (max-width: 500px) {
  .pad-lefri {
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .arrow {
    font-size: 10vw;
    left: 5vw;
    bottom: 7vw;
  }

  #carousel-2 .carousel-caption h3 {
    font-size: 5vw;
    margin-bottom: 0;
  }

  #carousel-2 .carousel-caption p {
    font-size: 3vw;
    margin-bottom: 0;
  }
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
</style>
