<template>
  <div>
    <b-icon
      @click="scrollToComponent('about')"
      :class="{ 'arrow d-none': true, 'd-block': this.scrolled }"
      icon="b-icon-arrow-up"
    />
    <a href="/" class="arrowLeft">
      <b-icon icon="b-icon-arrow-left" />
    </a>
    <div id="about" class="pad-lefri">
      <div class="left">
        <b-carousel
          id="carousel-1"
          v-model="slide1"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart1"
          @sliding-end="onSlideEnd1"
        >
          <b-carousel-slide v-for="(item, index) in slideData1" :key="index">
            <template #img>
              <img :src="item.img" :alt="'Slide ' + (index + 1)" />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="right">
        <p class="title">Garda Rizkyamien</p>
        <p class="description fm">
          I'm that ENFJ who's all about people but also needs his alone time,
          loves every kind of sports and a big fan of doing something new. When
          I’m not busy, you can find me writing blogs about self-development
          topics or capturing moments in the streets behind the camera. A Coffee
          Enthusiast that loves hopping around new cafes and seeks new
          experiences. Fun Fact: I’ve wandered to 2 countries and 9 different
          cities in the last 10 years and honestly, I've kinda gotten the hang
          of adapting to wherever life takes me!
        </p>
        <ul>
          <li v-for="(item, index) in pillItems" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div id="carousel">
      <b-carousel
        id="carousel-2"
        v-model="slide2"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart2"
        @sliding-end="onSlideEnd2"
      >
        <b-carousel-slide
          v-for="(item, index) in slideData2"
          :key="index"
          :caption="item.caption"
          :text="item.text"
        >
          <template #img>
            <img
              class="d-block img-fluid w-100 asdasd"
              :src="item.img"
              :alt="'Slide ' + (index + 1)"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div id="social">
      <p class="description fm">
        Thanks for visiting! You know, I believe that strangers are just friends
        that we have never met. So, let me know if your willing to know more or
        perhaps have any questions about me. I would be happy to help you out on
        that. Besides, I love to connect with new awesome folks out there. Don’t
        hesitate to reach out.
      </p>
      <div class="link">
        <ul>
          <li v-for="(item, index) in socialItems" :key="index">
            <a :href="item.link" target="_blank">{{ item.social }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="col-6">
        <p class="mb-0">
          Best Regards,
          <br />
          Garda.
        </p>
      </div>
      <div class="col-6 text-right">
        <p>Rizkyamien @2024 All Rights Reserved //</p>
      </div>
    </div>
  </div>
</template>

<script>
import About1 from '@/assets/AboutSlide/1.jpg'
import About2 from '@/assets/AboutSlide/2.jpg'
import About3 from '@/assets/AboutSlide/3.jpg'

import Slide1 from '@/assets/Slide/1.jpg'
import Slide2 from '@/assets/Slide/2.jpg'
import Slide3 from '@/assets/Slide/3.jpg'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'About',
  data() {
    return {
      pillItems: ['Design', 'Social Media', 'Digital Marketing', 'Storyteller'],
      scrolled: false,
      slide1: 0,
      sliding1: null,
      slideData1: [
        { caption: 'Title1', text: 'Description1', img: About1 },
        { caption: 'Title2', text: 'Description2', img: About2 },
        { caption: 'Title3', text: 'Description3', img: About3 },
      ],
      slide2: 0,
      sliding2: null,
      slideData2: [
        {
          caption: 'Bandung',
          text: `There is always this different feeling
when it comes to visiting Bandung, it seems like a mixture of warm hospitality, art, and cold breeze in a combination that is just very hard to explain. Please take me back!`,
          img: Slide1,
        },
        { caption: 'Mekkah', text: `One of the Holy Cities in Saudi. Mekkah, Birthplace of the last Prophet, and visiting their while doing umrah is unreal. It takes me back to those days where we were just studying about our religion, and visiting the place seems unreal.`, img: Slide2 },
        { caption: 'Jakarta', text: `Seeking to success in this city is something else, seeing everyone around Indonesia is fighting and thriving to succeed in the capital, and somehow everything seems so fast in this city. No time to slow down and catch a breath.`, img: Slide3 },
      ],
      socialItems: [
        {
          social: 'instagram.',
          link: 'https://www.instagram.com/rizkyamienn/',
        },
        {
          social: 'linkedin.',
          link: 'https://www.linkedin.com/in/garda-rizkyamien-316282153/',
        },
        { social: 'facebook.', link: 'https://www.facebook.com/020897GVD/' },
        {
          social: 'email.',
          link: 'mailto:gardarizkyamien@gmail.com',
        },
      ],
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    scrollToComponent(componentId) {
      const element = document.getElementById(componentId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    handleScroll() {
      if (window.scrollY >= window.innerHeight) {
        if (!this.scrolled) {
          this.scrolled = true
        }
      } else {
        if (this.scrolled) {
          this.scrolled = false
        }
      }
    },
    onSlideStart1(slide) {
      this.sliding1 = true
    },
    onSlideEnd1(slide) {
      this.sliding1 = false
    },
    onSlideStart2(slide) {
      this.sliding2 = true
    },
    onSlideEnd2(slide) {
      this.sliding2 = false
    },
  },
}
</script>

<style scoped>
.arrowLeft {
  position: absolute;
  right: 8.33vw;
  top: 4.38vw;
  font-size: 2.29vw;
}

#about {
  height: 100vh;
  padding-top: 12.92vw;
  display: flex;
}

#about .left {
  flex-grow: 0.4067;
  height: 31.67vw;
}

#about #carousel-1,
#about #carousel-1 img {
  height: 31.67vw;
  width: 25vw;
}

#about #carousel-1 ol.carousel-indicators {
  display: none !important;
}

#about .right {
  flex-grow: 1;
  flex-basis: auto;
  padding-top: 2.14vw;
  padding-left: 5.21vw;
}

#about .title {
  font-size: 4.28vw;
  line-height: 4.8vw;
  font-weight: 900;
  margin-bottom: 1.3vw;
}

#about .description {
  font-size: 0.94vw;
  max-width: 31.25vw;
  margin-bottom: 1.51vw;
}

#about ul {
  list-style: none;
  padding-inline-start: 0;
}

#about li {
  display: inline;
  list-style: none;
  font-size: 0.98vw;
  cursor: pointer;
  width: max-content;
  transition: all 0.5s ease;
  padding: 0.3vw 0.5vw;
  border: 1px solid #000000;
  border-radius: 1.8vw;
  margin-right: 0.6vw;
  font-weight: 900;
}

#about li:hover {
  background: #000;
  color: #fff;
}

#carousel {
  height: 100vh;
  padding-top: 3.5vh;
  padding-left: 5.83vw;
  padding-right: 5.83vw;
}

#carousel #carousel-2 img {
  height: 100%;
}

#carousel #carousel-2 h3 {
  font-size: 5vw !important;
}

#social {
  height: 100vh;
  padding-left: 19.27vw;
  padding-right: 19.27vw;
  padding-top: 8.54vw;
}

#social .description {
  font-size: 0.94vw;
  max-width: 40.63vw;
  margin-bottom: 24.22vw;
}

#social .link {
  width: 100%;
}

#social .link ul {
  list-style: none;
  padding-inline-start: 0;
  margin-left: auto;
  width: 6.25vw;
}

#social .link li {
  list-style: none;
  font-size: 1.26vw;
  cursor: pointer;
  width: max-content;
  transition: all 0.5s ease;
  background: #fff;
}

#social .link li:hover > a {
  font-weight: 900;
}

.footer {
  height: 10.78vw;
  padding-top: 4.9vw;
  display: flex;
  padding-left: 19.27vw;
  padding-right: 19.27vw;
}

.footer a,
.footer p {
  font-size: 1.25vw;
}

.footer a:hover {
  font-weight: 900;
}

@media screen and (max-width: 500px) {
  .arrowLeft {
    font-size: 10vw;
  }

  #about {
    padding-top: 20vw;
    display: block;
  }

  #about .left {
    height: 60vw;
    text-align: -webkit-center;
  }

  #about #carousel-1,
  #about #carousel-1 img {
    height: 60vw;
    width: 50vw;
  }

  #about #carousel-1 ol.carousel-indicators {
    display: none !important;
  }

  #about .right {
    flex-grow: 1;
    flex-basis: auto;
    padding-top: 5vw;
    padding-left: unset;
  }

  #about .title {
    font-size: 14vw;
    line-height: 14vw;
    margin-bottom: 5vw;
  }

  #about .description {
    font-size: 4vw;
    max-width: 100%;
    margin-bottom: 8vw;
  }

  #about li {
    display: inline-block;
    font-size: 4vw;
    padding: 0.5vw 1.5vw;
    border-radius: 4vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
  }

  #carousel {
    height: 43.33vh;
    padding-top: 10vh;
    padding-left: unset;
    padding-right: unset;
  }

  #social {
    height: 56.66vh;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 12vw;
  }

  #social .description {
    font-size: 4vw;
    max-width: 100%;
    margin-bottom: 10vw;
  }

  #social .link ul {
    width: 24vw;
  }

  #social .link li {
    font-size: 4vw;
  }

  .footer {
    height: 20vw;
    padding: 6vw;
  }

  .footer > .col-6 {
    padding: 0;
  }

  .footer a,
  .footer p {
    font-size: 2vw;
  }
}
</style>
